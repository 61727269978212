import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgSolar01 from "../../assets/leistungen/solar01.jpg"
import imgSolar02 from "../../assets/leistungen/solar02.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Solarthermie &amp; Photovoltaik"
      Content="Energiekosten aus fossilen Energieträgern werden auch in Zukunft&nbsp; 
        steigen! Mit Solaranlagen holen Sie sich ein eigenes Kraftwerk auf&nbsp; 
        das Dach. Wir stehen Ihnen nicht nur in der Beratung, Lieferung&nbsp; 
        und dem Einbau moderner und effizienter Produkte zur Seite,&nbsp; 
        sondern können Ihnen auch Informationen zu Förderprogrammen und&nbsp; 
        weiteren Möglichkeiten geben."
    />
    <LeistungBox
      Color="gray"
      Image={imgSolar01}
      IsImageLeft={true}
      Title="Solaranlagen"
      Content="Die Sonne ist ein unerschöpflicher Energielieferant. Nutzen Sie&nbsp; 
        die Energie der Sonne, um Ihr Trinkwasser zu erwärmen oder auch&nbsp; 
        für die Unterstützung Ihrer Raumheizung. Dies lohnt sich nicht nur&nbsp; 
        ökologisch, sondern auch ökonomisch und trägt zu einem besseren&nbsp; 
        Klima auch für zukünftige Generationen bei.
        <p />
        So können Sie sich ein Stück weit unabhängig von steigenden&nbsp; 
        Energiepreisen, Lieferengpässen und Rohstoffverknappung machen,&nbsp; 
        denn: die Sonne ist immer da. Selbst bei bedecktem Himmel wird&nbsp; 
        solare Energie produziert. Und Sie können davon profitieren."
    />
    <LeistungBox
      Color="white"
      Image={imgSolar02}
      IsImageLeft={false}
      Title="Photovoltaikanlagen"
      Content="Emissions- und geräuschlos kann mithilfe von Photovoltaikanlagen&nbsp; 
        Sonnenenergie umweltfreundlich in Strom umgewandelt werden. Es&nbsp; 
        gilt wichtige Aspekte zu berücksichtigen, um einen größtmöglichen&nbsp; 
        Ertrag zu erhalten. Wir beraten Sie umfassend zu diesem Thema."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
